
.container {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 20px;
    height: 100vh;
    cursor: url('./static/MiddleFinger.png'), auto;

    &__header {
        height: 10%;
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: 70%;
        border-radius: 10px;
        margin: auto;

        &-title {
            text-align: center;
            img {
                max-width: 100%;
            }
        }

        &-text {
            text-align: center;
            img {
                max-width: 100%;
            }
        }
    }

    &__footer {
        height: 10%;
    }
}